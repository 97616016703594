import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { VotingBarChart, VotingLineChart, VotingMap, VotingPollWorkerAge, VotingTable } from "story_components";
import * as d from "data/strength-in-numbers.js";
import COLORS from "utils/styles";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`Over the past few years, I've read two books that have kept me up at night.`}</MDXTag>
      <MDXTag name="p" components={components}>{`One of them is called `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.amazon.com/Merchants-Doubt-Handful-Scientists-Obscured/dp/1608193942"
        }}>{`The Merchant of Doubt: How a Handful of Scientists Obscured the Truth on Issues from Tobacco Smoke to Global Warming`}</MDXTag>{`.
The subtitle says it all; it's a stunningly depressing true story about how a
small group of scientists waged a campaign against science itself, with
remarkable success.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The other is called `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.amazon.com/Give-Us-Ballot-Struggle-America/dp/1250094720"
        }}>{`Give Us the Ballot: The Modern Struggle for Voting Rights in America`}</MDXTag>{`.
This one's a stunningly depressing true story about how a small group of racists
waged a campaign against voting rights for historically disenfranchised groups,
again with remarkable success.`}</MDXTag>
      <MDXTag name="p" components={components}>{`While these topics may sound like they fall under umbrellas of science, history,
or civics, there's plenty of relevant mathematical conversations here, too.
In terms of climate change, I wrote a story `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://mattlane.us/stories/warming-dots/"
        }}>{`last year`}</MDXTag>{`
on general mathematical models of natural resource depletion and its effects on
populations. In this story, I'd like to turn a mathematical lens on the topic of
voting, and slap a coat of paint on a bunch of tabular data.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`The Data`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's explore results from the past decade of United States elections. Every two
years, we elect our representatives in Congress, along with potentially a
senator or a president. Months after the election is complete, the U.S. Assistance
Commission (EAC) releases survey data on the election. This data includes information
on things like registration, voter participation, and the number of precincts
and poll workers. (More information can be found on their `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.eac.gov/research-and-data/datasets-codebooks-and-surveys/"
        }}>{`website`}</MDXTag>{`.)`}</MDXTag>
      <MDXTag name="p" components={components}>{`Along with the data, the EAC provides a report summarizing its findings. But
with a decade of past results to compare against, I was curious about
visualizing their findings across time. How have election patterns changed since
2008, if at all? To start answering this question, I pulled out the information
I was curious about, and consolidated it by year. `}</MDXTag>
      <MDXTag name="p" components={components}>{`On their own, unfortunately, numbers aren't the greatest storytellers. Let's try
to frame all of these values into a more cohesive narrative.`}</MDXTag>
      <MDXTag name="p" components={components}>{`(Note that results for the 2018 election aren't available yet. I'll add that
data in once it's released.)`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`The People`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's start with the fundamentals: who's registered, who's voting, and who isn't
voting despite eligibility? Using the map below, you can explore how different
states compare across every election since 2008. There are five different
statistics to choose from:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Active Registered Voters.`}</MDXTag>{` How many active registered voters did the state
report for that year?`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Election Participants.`}</MDXTag>{` How many ballots were received? Note that in some
jurisdictions can include rejected provisional ballots. Think of it as a slight
overestimate for the number of people who successfully voted.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Eligible Voters.`}</MDXTag>{` Of all of the folks in the state, how many were
`}<MDXTag name="em" components={components} parentName="p">{`eligible`}</MDXTag>{` to vote (at least 18 years old, citizens, and no criminal history
that would deprive them of their right to vote)? These numbers are taken from
the `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://www.electproject.org/home/voter-turnout/voter-turnout-data"
            }}>{`United States Elections Project`}</MDXTag>{`.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Registration Saturation.`}</MDXTag>{` What percentage of eligible voters are active
registered voters? Note that while this should be a value between 0% and 100%,
because we're dealing with estimates, there are certain data points with values
greater than 100%. For example, Kentucky and Maine in 2016 both have saturation
rates a bit above 100%.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Election Turnout.`}</MDXTag>{` What percentage of eligible voters actually
participated in the election?`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Nearly all of the data for these statistics comes from EAC reporting, though there
are some inconsistencies requiring alternative sources. In particular, the 2016 New York data
is from `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.elections.ny.gov/NYSBOE/enrollment/county/county_nov16.pdf"
        }}>{`here`}</MDXTag>{`,
and the 2014 Alabama data is from `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.sos.alabama.gov/sites/default/files/voter-pdfs/turnout.pdf"
        }}>{`here`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's how the country has looked through the lens of each of these statistics
since 2008:`}</MDXTag>
      <VotingMap caption="Figure 1: Map visualization of population data by year and state." selectOptions={d.mapOptionsForVoters} />
      <MDXTag name="p" components={components}>{`For a clearer picture of the trend across years within a state, here's a second
representation of the same data:`}</MDXTag>
      <VotingLineChart caption="Figure 2: Line representation of population data by year for a fixed state." selectOptionsForStatistic={d.lineOptionsForVoters} />
      <MDXTag name="p" components={components}>{`A few observations:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`As you might expect, presidential election years correspond to substantial
boosts in registration, participation, and overall turnout. For example, the
graph of nearly every state's election participant figures show a distinctive
zigzag shape, sloping up during presidential election years and down for the
midterms.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`In terms of average turnout across years, three of the top five states are
neighbors (Minnesota, Wisconsin, and Iowa). The bottom five performers are
more geographically distributed: the worst offenders are Mississippi, Texas,
West Virginia, Utah, and Hawaii.`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`Here's a table summarizing the findings. You can check out the top or bottom
performers for either registration saturation or voter turnout. Note that
North Dakota has no data on registered voters dating back to 2008, which is
why their saturation number is 0%.`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <VotingTable caption="Table 1: Average registration and turnout percentages by state." />
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Among recent elections, states have had a difficult time surpassing their 2008
turnout. In fact, 34 states recorded their highest turnout during that election,
as opposed to only 13 during the 2016 election.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`For more on the percentages, here are histograms highlighting the distrubtion of
registration saturation and turnout by year. Note, once again, the impact of a
presidential election:`}</MDXTag>
      <VotingBarChart caption="Figure 3: Bar graph of registration and turnout by state." dataCleaner={d.dataCleanerForFirstBarGraph} selectData={[d.lineOptionsForVoters.slice(-2)]} />
      <MDXTag name="p" components={components}>{`(The careful reader may have noticed that some states on both sides of the
political spectrum have higher registration numbers than estimated eligible
voters. Nothing nefarious is going on here; it's typically boils down to
inconsistences in methodology for coming up with these numbers. For more on this,
see `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.snopes.com/fact-check/us-more-registered-voters-than-adults/"
        }}>{`Snopes`}</MDXTag>{`.
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Wikipedia:Too_long;_didn%27t_read"
        }}>{`Tl;dr`}</MDXTag>{`: counting is hard.)`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`The Workers`}</MDXTag>
      <MDXTag name="p" components={components}>{`What I like about the EAC data is that it doesn't just track election
participants. It also tracks election `}<MDXTag name="em" components={components} parentName="p">{`workers`}</MDXTag>{`. While the data isn't as readily
available, many jurisdictions report how many poll workers they had, and some
even go so far as to report information on the ages of poll workers, or how
difficult it was to find them.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's start with the fundamentals. Here are our map and line charts revisited,
but with the focus on poll workers instead of voters. The statistics you can
explore are:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`% of Jurisdictions Reporting.`}</MDXTag>{` What percentage of jursidictions within a
state reported counts of poll workers on election day? Note that among those
reporting, an even smaller number provided information on the ages of their poll
workers or the difficulty in finding poll workers.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Poll Workers.`}</MDXTag>{` How many poll workers worked on election day? Since not
every jurisdiction reports these numbers, the counts you see are a lower
bound on the number of workers.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Polling Places.`}</MDXTag>{` How many polling places were there on election day? Since
not every jurisdiction reports these numbers, the counts you see are a lower
bound on the number of polling places.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Poll Workers per Polling Place.`}</MDXTag>{` On average, how many poll workers were
there for each polling place?`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Poll Workers per 1,000 Election Participants.`}</MDXTag>{` How many poll workers were
there as a fraction of the population? (Here we consider only election
participants from jurisdictions that reported poll worker information.)`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Polling Places per 1,000 Election Participants.`}</MDXTag>{` How many polling places
were there as a fraction of the population? (Here we consider only election
participants from jurisdictions that reported polling place information.)`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`Average Difficulty of Finding Poll Workers?`}</MDXTag>{` All jurisdictions are asked
to rate the difficulty of finding poll workers for a given election on a scale
of 1 (very easy) to 5 (very difficult). This shows an average difficulty score
across the state, taken from jurisdictions that reported a difficulty.`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`By each of these metrics, here's how the country has changed over time:`}</MDXTag>
      <VotingMap caption="Figure 4: Map visualization of poll worker data by year and state." selectOptions={d.mapOptionsForWorkers} />
      <MDXTag name="p" components={components}>{`Just like before, we can also dig into trends across years for a single state:`}</MDXTag>
      <VotingLineChart caption="Figure 5: Line representation of poll worker data by year for a fixed state." selectOptionsForStatistic={d.lineOptionsForWorkers} svgId="state-line-graph-2" />
      <MDXTag name="p" components={components}>{`What do you notice by exploring these graphs? Here are some things I noticed:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`There's an interesting association between the average number of poll workers
per polling location and the political leanings of voters within the state.
Among the five states with the lowest ratio of poll workers to polling places
(Oklahoma, Alabama, Mississippi, Alaska, Pennsylvania), four of them have
traditionally leaned Republican. Similarly, among the five states with the
highest ratio (Oregon, Maryland, New York, Virginia, Hawaii), four have
traditionally leaned Democrat.`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`It should be noted that Oregon is a bit of an outlier, since it has a robust
vote by mail system in place. This has the effect of skewing the poll worker
per polling place ratio quite high.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`The number of polling places per 1,000 participants sometimes says more about
the mechanics of that state's voting system than anything else. The three
states with the lowest ratio (Oregon, Washington, Colorado) are also the three
with the most robust vote by mail programs. On the other hand, of the four
states with the highest ratio (Utah, West Virginia, Mississippi,
Pennsylvania), only Utah and West Virginia offer some form of early voting.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`With fewer parcipants in off-years, the per capita statistics (poll workers
and polling places per 1,000 participants) tend to see a boost during the
midterms.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Across all states, reported difficulty of finding workers has trended slightly
upward, from about 3.07 in 2008 to 3.35 in 2016. Total number of jurisdictions
reporting difficulty also went down substantially between 2014 to 2016 (6,444 to
3,183).`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`In addition to reporting on the number of poll workers and the difficulty in
finding them, some jurisdictions also report statistics on the age distribution
of poll workers. Below is a breakdown of those age groups by state and year.
(Note that no states reported this information for 2008.)`}</MDXTag>
      <VotingPollWorkerAge caption="Figure 6: Pie chart of poll worker ages, by state and year." />
      <MDXTag name="p" components={components}>{`In the aggregate, distribution of poll worker ages has been relatively steady
across elections. In terms of youth participation, some states do better than
others. California leads the pack with poll workers under the age of 18; more
than 20% of reported workers for the 2012 election were minors.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`It's Party Time`}</MDXTag>
      <MDXTag name="p" components={components}>{`One other dimension that we haven't considered yet is the political leanings of
the population. Are there any trends among states that lean Republican or
Democratic?`}</MDXTag>
      <MDXTag name="p" components={components}>{`To answer this question, we need data on the political affiliations of state
electorates. Election results themselves provide some of this information, but
this data can be somewhat problematic. For example, for Congressional races some
districts are so heavily Republican or Democratic that candidates run unopposed,
making it impossible to suss out party affiliation from the final vote tallies.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In order to get a more complete picture, I pulled data from Gallup surveys on
party affiliation by state for each election year. These surveys record the
percentage of respondants that identify as Republican or lean Repbulican, as
well as the percentage that identify as Democrat or lean Democrat.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Below is another bar chart. Each bar corresponds to a state (including
Washington, D.C.). The more blue the bar, the more the state leans Democratic;
the more red the bar, the more Republican. You can adjust the election year as
well as select one of several statistics to see how party affiliation does (or
doesn't) play a role.`}</MDXTag>
      <VotingBarChart caption="Figure 7: Exploring the influence of party affiliation on other statistics." dataCleaner={d.dataCleanerForSecondBarGraph} selectData={[d.selectDataForSecondBarGraph]} colorRange={[COLORS.DARK_BLUE, COLORS.RED]} colorDomain={[-50, 50]} colorAccessor={d => d.rep_percent - d.dem_percent} />
      <MDXTag name="p" components={components}>{`Here are some observations:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`As has been `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://www.quorum.us/data-driven-insights/under-obama-democrats-suffer-largest-loss-in-power-since-eisenhower/291/"
            }}>{`well documented`}</MDXTag>{`,
Democratic party affiliation suffered huge setbacks between 2008 and 2016.
Democrats lost over a dozen governorships and hundreds of state legislature
seats between 2008 and 2016, as well as losing majorities in the House and a
supermajority in the Senate. These losses are reflected in the party affiliation
graphs for Democrats and Republicans between 2008 and 2016.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Midterm elections have substantially lower turnouts than presidential
elections. Within a given election, it looks like turnout tends to be higher in
states that are more likely to be tossup states. In other words, states that are
solid blue or solid red tend to have lower turnouts.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`While young people overall are much less likely to work at the polls, blue
states are more likely to have young poll workers than red states.`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`What other things do you notice?`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Conclusion`}</MDXTag>
      <MDXTag name="p" components={components}>{`Looking at election data over time can provide us with a pulse check on the
health of our grand experiment. The EAC data provides a great way to highlight
common election statistics like turnout. It's also useful able to shine a light
on the health of our elections by measuring how well they are staffed, and how
many polling places we provide to our citizens. Unfortunately, these latter
metrics appear to be headed in the wrong direction: poll workers are getting
harder to find, on average, and reporting on poll workers has decreased.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It's a sad fact that while many states are taking concrete steps towards making
voting easy and more accessible, other states are adopting proposals so
regressive they could been written by Jim Crow legislators more than a
century ago. In Florida, for example, legislators are trying to roll back
efforts to restore voting rights for felons by enacting a
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.nytimes.com/2019/03/22/opinion/voting-rights-florida.html"
        }}>{`modern day poll tax`}</MDXTag>{`.
In Tennessee, efforts are underway to criminalize common errors in
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.vox.com/policy-and-politics/2019/4/25/18516777/tennessee-senate-voter-registration-drives-legislation-fines"
        }}>{`voter registration drives`}</MDXTag>{`.
These efforts, though upsetting, are not that suprising when understood in the larger
context of the fight for voting rights detailed so well in `}<MDXTag name="em" components={components} parentName="p">{`Give Us the Ballot.`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`We're fortunate that organizations continue to collect data on both the
results and the general health of our elections. I've taken a stab at slicing
the data in some ways I found interesting, but I don't have a monopoly on
staring at numbers. If you're interested in digging deeper, you can find the raw
data `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/mmmaaatttttt/personal-site/blob/master/src/data/csv/voting_data_2008_2016.csv"
        }}>{`here`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sources:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.eac.gov/research-and-data/datasets-codebooks-and-surveys/"
          }}>{`Election Administration and Voting Survey Datasets, Codebooks, and Survey Instruments`}</MDXTag>{`, by the U.S. Election Assistance Commission`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://www.electproject.org/home/voter-turnout/voter-turnout-data"
          }}>{`Voter Turnout Data`}</MDXTag>{`, by the United States Elections Project`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.amazon.com/Merchants-Doubt-Handful-Scientists-Obscured/dp/1608193942"
          }}>{`The Merchants of Doubt`}</MDXTag>{`, by Naomi Oreskes and Erik Conway.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://www.amazon.com/Give-Us-Ballot-Struggle-America/dp/1250094720"
          }}>{`Give Us the Ballot`}</MDXTag>{`, by Ari Berman.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Gallup surveys on political affiliation by state: `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://news.gallup.com/poll/203117/gop-maintains-edge-state-party-affiliation-2016.aspx"
          }}>{`2016`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://news.gallup.com/poll/181475/massachusetts-maryland-democratic-states.aspx"
          }}>{`2014`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://news.gallup.com/poll/160175/blue-states-outnumber-red-states.aspx"
          }}>{`2012`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://news.gallup.com/poll/146234/number-solidly-democratic-states-cut-half.aspx"
          }}>{`2010`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://news.gallup.com/poll/114016/state-states-political-party-affiliation.aspx"
          }}>{`2008`}</MDXTag>{`.`}</MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "Strength in Numbers",
  "date": "2019-04-28",
  "featured_image": "../../images/featured_images/strength_in_numbers.jpg",
  "caption": "Exploring American voting trends in the 21st century.",
  "featured_image_caption": "Image credit: SnapwireSnaps from Pixabay.",
  "tags": ["statistics", "civics", "voting"]
};
      